<template>
  <v-form
    ref="cardForm"
    class="cardForm"
    v-if="(!selectedPaymentMethod || editEnabled) && !isDisabled"
  >
    <v-row>
      <v-col cols="12" sm="12" md="6" style="padding-bottom: 0">
        <v-select
          :items="paymentMethods"
          v-model="selectedPaymentMethod"
          @change="editEnabled = true"
          label="Πως επιθυμείτε να πληρώσετε;"
          outlined
          dense
          :disabled="isDisabled"
          item-text="text"
          item-value="value"
        >
          <template v-slot:item="{ item }">
            <font-awesome-icon
              :icon="item.icon"
              class="m-r-5"
            ></font-awesome-icon>
            {{ item.text }}
          </template></v-select
        >
      </v-col>
    </v-row>
    <v-row style="margin: 0px" v-if="!isDisabled">
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="$emit('closeForm')"
        class="m-r-10 font-weight-bold"
        style="text-transform: initial"
      >
        Άκυρο
      </v-btn>
      <v-btn
        @click="saveForm"
        color="orange"
        style="text-transform: initial; color: white"
      >
        Αποθήκευση
      </v-btn>
    </v-row>
  </v-form>
  <div v-else>
    <div
      class="d-flex justify-space-between"
      style="font-weight: 500; cursor: pointer"
    >
      <div>Τρόπος πληρωμής: {{ selectedPaymentMethod }}</div>
      <div v-if="!isDisabled" @click="selectedPaymentMethod = null">
        <font-awesome-icon :icon="faPen" class="m-r-5"></font-awesome-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { faCoins, faCreditCard, faPen } from "@fortawesome/pro-solid-svg-icons";
import { mapActions } from "vuex";
export default {
  props: {
    voucherData: {
      type: Object,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      faCoins,
      faCreditCard,
      faPen,
      selectedPaymentMethod: "",
      editEnabled: false,
      paymentMethods: [
        { text: "Με μετρητά κατά την παράδωση", value: "Cash", icon: faCoins },
        {
          text: "Με κάρτα κατά την παράδοση (POS)",
          value: "POS",
          icon: faCreditCard,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      paymentChoice: "livetracking/livetracking/paymentChoice",
    }),
    saveForm() {
      const isValid = this.$refs.cardForm.validate();
      if (isValid) {
        const payload = {
          vm: this,
          requestBody: {
            paymentMethod: this.selectedPaymentMethod,
            id: this.voucherData._id,
          },
        };
        this.paymentChoice(payload)
          .then(() => {
            this.editEnabled = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  computed: {},
  mounted() {
    this.selectedPaymentMethod = this.voucherData.trackingActions.paymentMethod;
  },
};
</script>

<style lang="scss">
.v-list-item__content {
  text-align: left;
}
</style>
